<template>
  <div>
    <el-autocomplete v-model="address" :fetch-suggestions="querySearch" placeholder="请输入详细地址" style="width: 100%" :trigger-on-focus="false"
      @select="handleSelect" />
    <baidu-map @click="getClickInfo" style="width: 100%; height: 400px; margin-top: 10px" class="map" :center="mapCenter" :zoom="mapZoom"
      :scroll-wheel-zoom="true" :ak="ak" @ready="handler">
    </baidu-map>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
export default {
  components: {
    BaiduMap,
  },
  props: ["lng", "lat", "position"],
  data() {
    return {
      ak: "cI2H63y27H04rdPytzZ1djBOIt62Yw2a",
      mapZoom: 15,
      mapCenter: { lng: 114.311586, lat: 30.598467 },
      address: "",
      selectAddress: {}
    };
  },
  watch: {
    lat: {
      handler(val) {
        if (val) {
          this.mapCenter.lat = val;
        }
      },
      immediate: true,
    },
    lng: {
      handler(val) {
        if (val) {
          this.mapCenter.lng = val;
        }
      },
      immediate: true,
    },
    position: {
      handler(val) {
        if (val) {
          this.address = val;
        } else {
          this.address = ''
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 初始化获取当前定位
    handler({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
      if (this.lng && this.lat) {
        this.mapCenter.lng = this.lng;
        this.mapCenter.lat = this.lat;
        this.makerCenter(this.mapCenter);
      } else {
        let geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(
          (res) => {
            this.mapCenter = res.point;
            this.makerCenter(this.mapCenter);
          },
          { enableHighAccuracy: true }
        );
      }
    },
    querySearch(queryString, cb) {
      var that = this;
      var myGeo = new this.BMap.Geocoder();
      myGeo.getPoint(
        queryString,
        function (point) {
          if (point) {
            that.makerCenter(point);
          }
        },
        this.locationCity
      );
      var options = {
        onSearchComplete: function (results) {
          if (local.getStatus() === 0) {
            // 判断状态是否正确
            var s = [];
            for (var i = 0; i < results.getCurrentNumPois(); i++) {
              var x = results.getPoi(i);
              var item = { value: x.address + x.title, point: x.point };
              s.push(item);
              cb(s);
            }
          } else {
            cb();
          }
        },
      };
      var local = new this.BMap.LocalSearch(this.map, options);
      local.search(queryString);
    },
    handleSelect(item) {
      this.selectAddress = {
        lng: item.point.lng,
        lat: item.point.lat,
        address: item.value,
      };
      this.$emit("point", this.selectAddress);
      this.makerCenter(item.point);
    },
    makerCenter(point) {
      if (this.map) {
        this.map.clearOverlays();
        this.map.addOverlay(new this.BMap.Marker(point));
        this.mapCenter.lng = point.lng;
        this.mapCenter.lat = point.lat;
        this.mapZoom = 15;
      }
    },
    /** *
     * 地图点击事件。
     */
    getClickInfo(e) {
      // 调整地图中心位置
      this.mapCenter.lng = e.point.lng;
      this.mapCenter.lat = e.point.lat;
      this.makerCenter(e.point);
      // 此时已经可以获取到BMap类
      if (this.BMap) {
        const that = this;
        // Geocoder() 类进行地址解析
        // 创建地址解析器的实例
        const geoCoder = new this.BMap.Geocoder();
        // getLocation() 类--利用坐标获取地址的详细信息
        // getPoint() 类--获取位置对应的坐标
        geoCoder.getLocation(e.point, (res) => {
          // console.log("获取经纬度", e.point, "获取详细地址", res);
          this.address = res.address;
          this.selectAddress = {
            lng: e.point.lng,
            lat: e.point.lat,
            address: res.address,
          };
          this.$emit("point", this.selectAddress);
        });
      }
    },
  },
};
</script>

<style></style>
